<template>
	<div class="flex_r br_1_eee">
		<div class="l">
			<div class="fs16 pt20 pb20 ml20">报名信息</div>
			<div class="flex_r">
				<div class="img ml20 mr20">
					<img width="360" height="360" src="//img.alicdn.com/imgextra/i3/26635013/O1CN01GXIMr31mu05UVWwIS_!!26635013.jpg" alt="">
					<div class="flex_r f_jc_b pt14 pb14">
						<img width="60" height="60" src="//img.alicdn.com/imgextra/i3/26635013/O1CN01GXIMr31mu05UVWwIS_!!26635013.jpg" alt="">
						<img width="60" height="60" src="//img.alicdn.com/imgextra/i3/26635013/O1CN01GXIMr31mu05UVWwIS_!!26635013.jpg" alt="">
						<img width="60" height="60" src="//img.alicdn.com/imgextra/i3/26635013/O1CN01GXIMr31mu05UVWwIS_!!26635013.jpg" alt="">
						<img width="60" height="60" src="//img.alicdn.com/imgextra/i3/26635013/O1CN01GXIMr31mu05UVWwIS_!!26635013.jpg" alt="">
						<img width="60" height="60" src="//img.alicdn.com/imgextra/i3/26635013/O1CN01GXIMr31mu05UVWwIS_!!26635013.jpg" alt="">
					</div>
					<div class="tc br_1_eee pt8 pb8 mb30">月销量、累计评价、收藏</div>
				</div>
				<div class="goods_info flex1">
					<div class="tc br_1_eee pt8 pb8 mb30 ml20 mr20">商品名称</div>
					<div class="flex_r f_ai_c">
						<div class="tc br_1_eee pt8 pb8 mb30 w_100 ml20 mr10">店铺名称</div>
						<div class="tc br_1_eee pt8 pb8 mb30 w_100 ml10 mr10">店铺DSR评分</div>
					</div>
					<div  class="flex_r f_ai_c">
						<div class="tc br_1_eee pt8 pb8 mb30 w_100 ml20 mr10">在售价</div>
						<div class="tc br_1_eee pt8 pb8 mb30 w_100 ml10 mr10 block_info" v-for="(item, i) in attrList1" :key="i" :class="item.show ? '' : 'hide'">最低价</div>
						<div class="tc br_1_eee pt8 pb8 mb30 w_100 ml10 mr10">历史最低价</div>
					</div>
					<div class="w_100 pt8 pb8 bg_red brs_4 br_1_eee tc ml20 cr_fff">查看价格走势</div>
					<div class="specification br_1_eee tc ml20">商品规格</div>
				</div>
			</div>
			<ul>
				<li v-for="(item, i) in goodsDetailFormList" :key="i">
					<div v-if="item.type === 'title'" class="fs16 ml30 mt20 mb20">{{item.label}}</div>
					<div :class="item.show ? '' : 'hide'" class="tc block" v-else>{{item.label}}</div>
				</li>
			</ul>
			<div class="tc mt20">
				<el-button>取消</el-button>
				<el-button class="ml20 mr20" type="danger"><router-link class="cr_fff" tag="a" target="_blank" :to="{path:'/configurationView'}">预览</router-link></el-button>
				<el-button type="primary">保存</el-button>
			</div>
		</div>
		<div class="r bg_default pt10 pb10 pl10 pr10 br_l_1_eee">
			<div class="flex_r f_jc_b fs18 pb20">
				<span>信息列表</span>
				<span>是否必填</span>
			</div>
			
			<ul>
				<li class="br_t_1_eee pt10 mb14">
					<span class="cr_ff0">注：请选择商家报名时所需的信息</span>
					<div class="flex_r f_jc_b mt12" v-for="item in attrList1" :key="item.prop">
						<el-checkbox @change="checkboxChange($event, item)" v-model="item.show">{{item.label}}</el-checkbox>
						<el-switch
							v-model="item.required">
						</el-switch>
					</div>
				</li>
				<li class="" v-for="(item, i) in goodsDetailFormList" :key="i">
					<div class="fs16 pt14 br_t_1_eee mb14" v-if="item.type === 'title'">{{item.label}}</div>
					<div v-else class="flex_r f_jc_b mb14">
						<el-checkbox @change="checkboxChange($event, item)" v-model="item.show">{{item.label}}</el-checkbox>
						<el-switch
							v-model="item.required">
						</el-switch>
					</div>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
import {goodsDetailFormList} from '@data/goods'
export default {
	data() {
		return {
			attrList1: [
				{
					label: '最低价',
					prop: '1',
					required: false,
					show: true
				},
				{
					label: '最低价说明',
					prop: '2',
					required: false,
					show: true
				}
			],
			goodsDetailFormList,
			// attrList2: [
			// 	{
			// 		label: '对接人',
			// 		prop: '1',
			// 		required: false,
			// 		show: true
			// 	},
			// 	{
			// 		label: '意向佣金比例',
			// 		prop: '2',
			// 		required: false,
			// 		show: true
			// 	},
			// 	{
			// 		label: '是否预售',
			// 		prop: '1',
			// 		required: false,
			// 		show: true
			// 	},
			// 	{
			// 		label: '赠品邮寄方式',
			// 		prop: '2',
			// 		required: false,
			// 		show: true
			// 	},
			// 	{
			// 		label: '第二件优惠',
			// 		prop: '1',
			// 		required: false,
			// 		show: true
			// 	},
			// 	{
			// 		label: '第三件优惠',
			// 		prop: '2',
			// 		required: false,
			// 		show: true
			// 	},
			// 	{
			// 		label: '其他优惠',
			// 		prop: '2',
			// 		required: false,
			// 		show: true
			// 	}
			// ],
			// attrList3: [
			// 	{
			// 		label: '是否有保质期',
			// 		prop: '1',
			// 		required: false,
			// 		show: true
			// 	},
			// 	{
			// 		label: '生产日期',
			// 		prop: '2',
			// 		required: false,
			// 		show: true
			// 	},
			// 	{
			// 		label: '保质期',
			// 		prop: '3',
			// 		required: false,
			// 		show: true
			// 	},
			// 	{
			// 		label: '发货时长',
			// 		prop: '4',
			// 		required: false,
			// 		show: true
			// 	},
			// 	{
			// 		label: '快递公司',
			// 		prop: '5',
			// 		required: false,
			// 		show: true
			// 	},
			// 	{
			// 		label: '不包邮地区',
			// 		prop: '6',
			// 		required: false,
			// 		show: true
			// 	},
			// 	{
			// 		label: '不发件地区',
			// 		prop: '7',
			// 		required: false,
			// 		show: true
			// 	},
			// 	{
			// 		label: '忌口人群',
			// 		prop: '8',
			// 		required: false,
			// 		show: true
			// 	},
			// 	{
			// 		label: '最大客服数',
			// 		prop: '9',
			// 		required: false,
			// 		show: true
			// 	},
			// 	{
			// 		label: '库存',
			// 		prop: '10',
			// 		required: false,
			// 		show: true
			// 	},
			// 	{
			// 		label: '是否控库存',
			// 		prop: '11',
			// 		required: false,
			// 		show: true
			// 	}
			// ],
			// attrList4: [
			// 	{
			// 		label: '联系人',
			// 		prop: '1',
			// 		required: false,
			// 		show: true
			// 	}
			// ],
			// attrList5: [
			// 	{
			// 		label: '商品卖点',
			// 		prop: '1',
			// 		required: false,
			// 		show: true
			// 	},
			// 	{
			// 		label: '备注',
			// 		prop: '2',
			// 		required: false,
			// 		show: true
			// 	},
			// 	{
			// 		label: '正品认证',
			// 		prop: '3',
			// 		required: false,
			// 		show: true
			// 	},
			// 	{
			// 		label: '正品认证备注',
			// 		prop: '4',
			// 		required: false,
			// 		show: true
			// 	}
			// ],
			// attrList6: [
				
			// ],
		}
	},
	methods: {
		checkboxChange(val, item) {
			if(!val) {
				this.$set(item, 'required', false)
			}
		}
	}
}
</script>

<style lang="scss" scoped>
.l {
	flex: 1;
	height: calc(100vh - 160px);
	overflow-y: auto;
}
.r {
	width: 250px;
	height: calc(100vh - 160px);
	overflow-y: auto;
}
.block {
	width: 600px;
	border: 1px solid #eee;
	line-height: 30px;
	margin: 8px 0 8px 30px;
	&.hide {
		background-color: #EBEEF5;
	}
}
.block_info.hide {
	background-color: #EBEEF5;
}
.w_100 {
	width: 100px;
}
.specification {
	height: 200px;
	line-height: 200px;
}
</style>