export const goodsApplyTableConf = [
	{
		label: '直播日期',
		prop: 'zbtime',
		width: 200
	},
	{
		label: '商品状态',
		prop: 'statusInfo'
	},
	{
		label: '商品类目',
		prop: 'name'
	},
	{
		label: '商品名称',
		prop: 'title'
	},
	{
		label: '商品图片',
		prop: 'account5'
	},
	{
		label: '店铺名称',
		prop: 'nick'
	},
	{
		label: '在售价',
		prop: 's_price'
	},
	{
		label: '近90天最低价',
		prop: 'account8'
	},
	{
		label: '直播到手价',
		prop: 'zb_price'
	},
	{
		label: '优惠描述',
		prop: 'content'
	},
	{
		label: '联系信息',
		prop: 'account12'
	},
	{
		label: '审核备注',
		prop: 'note'
	},
	{
		label: '商品链接',
		prop: 'account14'
	},
	{
		label: '操作',
		prop: 'operation',
		width: 90
	}
]

export const searchFormList = [
]


export const goodsApplyArrangeTableConf = [
	{
		label: '日期',
		prop: 'account1',
		width: 200
	},
	{
		label: '直播主题',
		prop: 'account2'
	},
	{
		label: '意向商品总数',
		prop: 'account3'
	},
	{
		label: '已确定商品总量',
		prop: 'account4'
	},
	{
		label: '负责人',
		prop: 'account5'
	},
	{
		label: '提醒日期',
		prop: 'account6'
	},
	{
		label: '在售价',
		prop: 'account7'
	},
	{
		label: '备注',
		prop: 'account8'
	}
]

export const goodsDetailFormList = [
	{
		label: '直播信息',
		prop: '1',
		type: 'title',
		required: false,
		show: true
	},
	{
		label: '商品链接',
		prop: 'item_url',
		type: 'input',
		required: false,
		show: true
	},
	// {
	// 	label: '期望直播日期',
	// 	prop: '2',
	// 	type: 'zbtime',
	// 	required: false,
	// 	show: true
	// },
	{
		label: '意向佣金比例',
		type: 'input',
		prop: 'yx_rate',
		append: '%',
		required: false,
		show: true
	},
	// {
	// 	label: '对接人',
	// 	type: 'select',
	// 	prop: '4',
	// 	options: [],
	// 	required: false,
	// 	show: true
	// },
	{
		label: '报名规格',
		prop: '5',
		type: 'solt',
		required: false,
		show: true
	},
	{
		label: '是否预售',
		type: 'select',
		prop: 'ys',
		options: [
			{
				name: '是',
				id: 1
			},
			{
				name: '否',
				id: 2
			}
		],
		required: false,
		show: true
	},
	{
		label: '定金',
		prop: 'dj',
		type: 'solt',
		required: false,
		show: true
	},
	{
		label: '优惠方式',
		type: 'select',
		prop: 'yh_type',
		options: [
			{
				name: '无',
				id: 1
			},
			{
				name: '拍下立减',
				id: 2
			},
			{
				name: '详情页领券',
				id: 3
			},
			{
				name: '联系客服领取',
				id: 4
			},
			{
				name: '其他',
				id: 5
			},
			{
				name: '直接改价',
				id: 6
			}
		],
		required: false,
		show: true
	},
	{
		label: '优惠',
		prop: '8',
		type: 'solt',
		required: false,
		show: true
	},
	{
		label: '直播到手价',
		prop: 'zb_price',
		type: 'span',
		required: false,
		show: true
	},
	{
		label: '服务费',
		prop: 'service_fee',
		type: 'input',
		required: false,
		show: true
	},
	{
		label: '保证金',
		prop: 'deposit',
		type: 'input',
		required: false,
		show: true
	},
	{
		label: '佣金比例',
		type: 'input',
		prop: 'rate',
		append: '%',
		required: false,
		show: true
	},
	{
		label: '第二件优惠',
		type: 'input',
		prop: 'tw_yh',
		required: false,
		show: true
	},
	{
		label: '第三件优惠',
		type: 'input',
		prop: 'th_yh',
		required: false,
		show: true
	},
	{
		label: '其他优惠',
		type: 'input',
		prop: '15',
		required: false,
		show: true
	},
	{
		label: '赠品',
		type: 'input',
		prop: 'zp',
		required: false,
		show: true
	},
	{
		label: '赠品邮寄方式',
		type: 'input',
		prop: 'zp_send_type',
		required: false,
		show: true
	},
	{
		label: '直播间抽奖',
		type: 'input',
		prop: 'zbj_cj',
		required: false,
		show: true
	},
	{
		label: '优惠券链接',
		type: 'input',
		prop: 'yh_link',
		required: false,
		show: true
	},
	{
		label: '佣金链接',
		type: 'input',
		prop: 'rate_link',
		required: false,
		show: true
	},
	{
		label: '基本信息',
		type: 'title',
		prop: '21',
		required: false,
		show: true
	},
	{
		label: '是否有保质期',
		type: 'select',
		prop: 'is_day',
		options: [
			{
				name: '是',
				id: 1
			},
			{
				name: '否',
				id: 2
			}
		],
		required: false,
		show: true
	},
	{
		label: '生产日期',
		type: 'date',
		prop: 'sc_day',
		required: false,
		show: true
	},
	{
		label: '发货时长',
		type: 'input',
		prop: 'send_time',
		append: '小时',
		required: false,
		show: true
	},
	{
		label: '快递公司信息',
		type: 'input',
		prop: 'kd',
		required: false,
		show: true
	},
	{
		label: '不发件地区',
		type: 'input',
		prop: 'bfj',
		required: false,
		show: true
	},
	{
		label: '不包邮地区',
		type: 'input',
		prop: 'bby',
		required: false,
		show: true
	},
	{
		label: '忌口人群',
		type: 'input',
		prop: 'jikou',
		required: false,
		show: true
	},
	{
		label: '最大客服数',
		type: 'input',
		prop: 'kf',
		required: false,
		show: true
	},
	{
		label: '库存',
		type: 'input',
		prop: 'sku',
		required: false,
		show: true
	},
	{
		label: '联系方式',
		type: 'title',
		disabled: true,
		prop: '30',
		required: false,
		show: true
	},
	{
		label: '联系人',
		type: 'input',
		disabled: true,
		prop: 'username',
		required: false,
		show: true
	},
	{
		label: '联系电话',
		type: 'input',
		disabled: true,
		prop: 'mobile',
		required: false,
		show: true
	},
	{
		label: '联系微信',
		type: 'input',
		disabled: true,
		prop: 'wx',
		required: false,
		show: true
	},
	{
		label: '其他信息',
		type: 'title',
		prop: '32',
		required: false,
		show: true
	},
	{
		label: '商品卖点',
		type: 'solt',
		prop: '33',
		required: false,
		show: true
	},
	// {
	// 	label: '试样记录',
	// 	type: 'input',
	// 	prop: '34',
	// 	required: false,
	// 	show: true
	// },
	{
		label: '备注',
		type: 'input',
		prop: '35',
		required: false,
		show: true
	},
	// {
	// 	label: '正品认证',
	// 	type: 'span',
	// 	prop: '36',
	// 	required: false,
	// 	show: true
	// }
]